<template>
  <div v-loading="loading" class="reward-activity-info-block">
    <p class="card-title">合約內容</p>
    <BaseElForm v-show="!formData.isSelect && !loading" ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="預覽訂閱方案內容" prop="name">
        <BaseElSelect v-model="previewPlan" placeholder="請選擇">
          <BaseElSelectOption v-for="item in subscribePlanList" :key="item.id" :label="item.name" :value="item.id" />
        </BaseElSelect>
      </BaseElFormItem>
      <div v-if="previewPlan" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[20px]">
            <div v-for="item in planData" :key="item.id" class="flex flex-col gap-[10px]">
              <span class="text-gray-100 font-bold text-normal">{{ item.name }}</span>
              <div v-for="subItem in item.items" :key="subItem.id" class="flex gap-[20px] pl-[16px] text-gray-80 text-sub">
                <span class="font-medium w-[120px]">{{ subItem.label }}</span>
                <span class="font-normal">{{ subItem.value }}</span>
              </div>
            </div>
          </div>
        </GrayBlockContainer>
      </div>
      <BaseElFormItem label="合約內容" prop="content">
        <el-dropdown class="replaceContract" @command="replaceContract">
          <span class="el-dropdown-link">
            套用合約範本<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="system">軟體系統訂閱合約範本</el-dropdown-item>
            <el-dropdown-item command="gym">健身房訂閱合約範本</el-dropdown-item>
            <el-dropdown-item command="product">實體商品配送合約範本</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <quillEditor v-model="formData.content" :options="editorOption" />
        <p class="text-sub text-warning">請留意合約描述的細節均符合選用此合約的訂閱方案</p>
      </BaseElFormItem>
    </BaseElForm>
    <template v-if="formData.isSelect && !loading">
      <p class="font-bold text-xl text-black mb-[32px]">{{ formData.planName }}</p>
      <div class="previewArea ql-editor" v-html="formData.content" />
    </template>
    <BaseDialog
      v-if="modal.warning"
      width="566px"
      title="提醒"
      @confirm="confirmWarnDialog"
      @cancel="modal.warning = false"
      @close="modal.warning = false"
    >
      <div class="dialog-content">
        <p class="mb-[20px]">將清除已輸入的合約內容，套用系統預設的合約範本。是否確定套用？</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { noEmptyRules } from '@/validation'
import { get, filter, map } from 'lodash'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useSubscribe } from '@/use/useSubscribe'
import { systemContract, gymContract, productContract } from '@/config/subscribe'

export default defineComponent({
  name: 'SubscribeContentBlock',
  components: { quillEditor, GrayBlockContainer, BaseDialog },
  props: ['FormsContext', 'contractData'],
  setup (props) {
    const { getSubscribePlan, subscribePlanList } = useSubscribe()
    const { editorOption } = useEditor()
    const formRef = ref(null)
    const formData = reactive({
      content: null,
      isSelect: null,
      planName: null,
    })
    const loading = ref(false)
    const contractType = ref(null)
    const previewPlan = ref(null)
    const formRules = {
      content: [noEmptyRules()],
    }
    const modal = reactive({
      warning: false,
    })
    const planData = computed(() => {
      const plan = filter(subscribePlanList.value, item => item.id === previewPlan.value)
      return map(plan, (item) => {
        const equityContents = map(get(item, 'planEquityPack.equityPack.equities'), item => get(item, 'name')).join('、')
        return {
          id: get(item, 'id'),
          name: get(item, 'name'),
          items: [
            { label: '品項組合名稱', value: get(item, 'planEquityPack.equityPack.name') || '-' },
            { label: '品項內容', value: equityContents || '-' },
            { label: '單次訂閱時長', value: `${get(item, 'periodSettings.fields.periods')} 個月` }, // TODO: p1 只有monthly
            { label: '單次訂閱期數', value: `${get(item, 'periodSettings.fields.periods')} 期` },
            { label: '每期扣款金額', value: `$ ${get(item, 'installmentSettings.fields.amount')}` },
          ],
        }
      })
    })
    const syncData = () => {
      const data = props.contractData
      formData.content = data.content
      formData.isSelect = !!get(data, 'planContracts.length')
      formData.planName = get(data, 'planContracts[0].plan.name')
    }

    const compactData = computed(() => {
      const data = {
        content: get(formData, 'content'),
      }
      return { ...data }
    })
    const replaceContract = (data) => {
      contractType.value = data
      modal.warning = true
    }
    const confirmWarnDialog = () => {
      modal.warning = false
      if (contractType.value === 'system') formData.content = systemContract
      if (contractType.value === 'gym') formData.content = gymContract
      if (contractType.value === 'product') formData.content = productContract
    }
    onMounted(async () => {
      await nextTick()
      loading.value = true
      await getSubscribePlan()
      loading.value = false
      props.FormsContext.setFormRef('contract', formRef.value)

      if (get(props.contractData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('contract', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      editorOption,
      replaceContract,
      modal,
      confirmWarnDialog,
      previewPlan,
      subscribePlanList,
      planData,
      loading,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content, .previewArea {
  @apply w-[560px]
}
::v-deep .ql-container.ql-snow, ::v-deep .ql-container.ql-snow .ql-editor {
  @apply min-h-[200px];
}
.replaceContract {
  @apply absolute right-0 bottom-full underline cursor-pointer;
}
</style>
